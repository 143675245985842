import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import MouseScrollAnimated from "../components/MouseScrollAnimated";

import YellowSection from "../components/YellowSection";

// Assets
import Section1Image1 from "../images/icons/about_section1-1.svg";

// Styles
import * as styles from "../styles/pages/about.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.heroSection}>
      <motion.div {...revealContainerProps} className="sectionContainer">
        <motion.div className={styles.heroSection__content}>
          <motion.div {...revealContainerWithChildrenProps}>
            <motion.h1
              variants={revealVariant}
              className={styles.heroSection__title}
            >
              <Trans
                i18nKey="about.heroSection.title"
                values={{ highlight: t("home.heroSection.title.highlight") }}
                components={[<span />]}
              />
            </motion.h1>
            <motion.p
              variants={revealVariant}
              className={styles.heroSection__text}
            >
              {t("about.heroSection.description")}
            </motion.p>
          </motion.div>
          <div variants={revealVariant} className={styles.heroSection__image}>
            <Section1Image1 />
          </div>
        </motion.div>
        <MouseScrollAnimated />
      </motion.div>
    </section>
  );
};

const AppSection = ({ image }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.appSection}>
      <motion.div className={`${styles.appSection__content} sectionContainer`}>
        <motion.div {...revealContainerWithChildrenProps}>
          <motion.h2
            variants={revealVariant}
            className={styles.appSection__title}
          >
            {t("about.appSection.title")}
          </motion.h2>
          <motion.p
            variants={revealVariant}
            className={styles.appSection__text}
          >
            {t("about.appSection.text1")}
          </motion.p>
          <motion.p
            variants={revealVariant}
            className={`${styles.appSection__text} `}
          >
            {t("about.appSection.text2")}
          </motion.p>
        </motion.div>
        <motion.div
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.appSection__image}
        >
          <GatsbyImage
            className="full-height-image"
            image={getImage(image)}
            alt=""
          />
        </motion.div>
      </motion.div>
    </section>
  );
};

const TeamSection = ({ image }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.teamSection}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.teamSection__content} sectionContainer`}
      >
        <motion.h2
          variants={revealVariant}
          className={styles.teamSection__title}
        >
          {t("about.teamSection.title")}
        </motion.h2>
        <motion.p variants={revealVariant} className={styles.teamSection__text}>
          {t("about.teamSection.text1")}
        </motion.p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
          },
        }}
        viewport={{
          amount: 0.5,
          once: true,
        }}
        className={styles.teamSection__image}
      >
        <GatsbyImage
          className="full-height-image"
          image={getImage(image)}
          alt=""
        />
      </motion.div>
    </section>
  );
};

const CultureSection = ({ image }) => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.cultureSection} sectionContainer`}>
      <motion.div
        {...revealContainerProps}
        variants={revealVariant}
        className={styles.cultureSection__card}
      >
        <div className={styles.cultureSection__card__image}>
          <GatsbyImage
            className="full-height-image"
            image={getImage(image)}
            alt=""
          />
        </div>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.cultureSection__card__content}
        >
          <motion.h2
            variants={revealVariant}
            className={styles.cultureSection__title}
          >
            {t("about.cultureSection.title")}
          </motion.h2>
          <motion.p
            variants={revealVariant}
            className={styles.cultureSection__text}
          >
            <Trans
              i18nKey="about.cultureSection.description"
              values={{ link: t("about.cultureSection.link") }}
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                <a
                  href="https://zeal.breezy.hr/"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </motion.p>
        </motion.div>
      </motion.div>
    </section>
  );
};

const ValuesSection = ({ images }) => {
  const { t } = useTranslation();
  const valuesSectionData = [
    {
      title: t("about.valuesSection.title1"),
      description: [t("about.valuesSection.description1")],
      image: getImage(images[0].node.childImageSharp),
    },
    {
      title: t("about.valuesSection.title2"),
      description: [
        t("about.valuesSection.description2.1"),
        "space",
        t("about.valuesSection.description2.2"),
      ],
      image: getImage(images[1].node.childImageSharp),
    },
    {
      title: t("about.valuesSection.title3"),
      description: [
        t("about.valuesSection.description3.1"),
        t("about.valuesSection.description3.2"),
      ],
      image: getImage(images[2].node.childImageSharp),
    },
    {
      title: t("about.valuesSection.title4"),
      description: [
        t("about.valuesSection.description4.1"),
        "space",
        t("about.valuesSection.description4.2"),
      ],
      image: getImage(images[3].node.childImageSharp),
    },
    {
      title: t("about.valuesSection.title5"),
      description: [
        t("about.valuesSection.description5.1"),
        "space",
        t("about.valuesSection.description5.2"),
      ],
      image: getImage(images[4].node.childImageSharp),
    },
  ];
  return (
    <section className={styles.valuesSection}>
      <motion.div className="sectionContainer">
        <motion.h2
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.valuesSection__title}
        >
          {t("about.valuesSection.title")}
        </motion.h2>
        <motion.p
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.valuesSection__description}
        >
          {t("about.valuesSection.description")}
        </motion.p>
        <motion.div className={styles.valuesSection__cards}>
          {valuesSectionData.map((item) => (
            <motion.div
              {...revealContainerProps}
              variants={revealVariant}
              className={styles.valuesSection__card}
              key={item.title}
            >
              <div className={styles.valuesSection__card__image}>
                <GatsbyImage
                  className="full-height-image"
                  image={item.image}
                  alt=""
                />
              </div>
              <motion.div
                {...revealContainerWithChildrenProps}
                className={styles.valuesSection__card__content}
              >
                <motion.h3
                  variants={revealVariant}
                  className={styles.valuesSection__card__title}
                >
                  {item.title}
                </motion.h3>
                {item.description.map((text, index) =>
                  text === "space" ? (
                    <br />
                  ) : (
                    <motion.p
                      variants={revealVariant}
                      className={styles.valuesSection__card__description}
                      key={index}
                    >
                      {text}
                    </motion.p>
                  )
                )}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

const AboutPage = ({
  data: {
    appSectionImage,
    teamSectionImage,
    cultureSectionImage,
    valuesSectionImages,
  },
}) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <HeroSection />
      <AppSection image={appSectionImage} />
      <TeamSection image={teamSectionImage} />
      <CultureSection image={cultureSectionImage} />
      <ValuesSection images={valuesSectionImages.edges} />
      <YellowSection
        title={t("about.section6.title")}
        description={t("about.section6.description")}
        url="https://zeal.breezy.hr/"
        btnText={t("about.section6.button")}
      />
    </Layout>
  );
};

export default AboutPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    appSectionImage: file(name: { regex: "/about-section-2/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    teamSectionImage: file(name: { regex: "/about-team/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    cultureSectionImage: file(name: { regex: "/about-culture/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    valuesSectionImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/about-values/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={isArabic ? "عنا" : "About"} />;
};
