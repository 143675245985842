// extracted by mini-css-extract-plugin
export var appSection = "about-module--appSection--0b007";
export var appSection__content = "about-module--appSection__content--daf20";
export var appSection__image = "about-module--appSection__image--d7dc2";
export var appSection__text = "about-module--appSection__text--9c034";
export var appSection__title = "about-module--appSection__title--0d3a9";
export var cultureSection = "about-module--cultureSection--ff4e0";
export var cultureSection__card = "about-module--cultureSection__card--77f4f";
export var cultureSection__card__content = "about-module--cultureSection__card__content--c8f2e";
export var cultureSection__card__image = "about-module--cultureSection__card__image--7aeef";
export var cultureSection__text = "about-module--cultureSection__text--8a52f";
export var cultureSection__title = "about-module--cultureSection__title--d88e2";
export var heroSection = "about-module--heroSection--f9740";
export var heroSection__content = "about-module--heroSection__content--e82dd";
export var heroSection__image = "about-module--heroSection__image--b6ab6";
export var heroSection__row1 = "about-module--heroSection__row1--678c2";
export var heroSection__text = "about-module--heroSection__text--316a5";
export var heroSection__title = "about-module--heroSection__title--cdcb8";
export var teamSection = "about-module--teamSection--7bb0d";
export var teamSection__content = "about-module--teamSection__content--51e31";
export var teamSection__image = "about-module--teamSection__image--2d209";
export var teamSection__text = "about-module--teamSection__text--b0e5b";
export var teamSection__title = "about-module--teamSection__title--be0cf";
export var valuesSection = "about-module--valuesSection--0610b";
export var valuesSection__card = "about-module--valuesSection__card--86239";
export var valuesSection__card__content = "about-module--valuesSection__card__content--c6e72";
export var valuesSection__card__description = "about-module--valuesSection__card__description--41831";
export var valuesSection__card__image = "about-module--valuesSection__card__image--75c8f";
export var valuesSection__card__title = "about-module--valuesSection__card__title--842a0";
export var valuesSection__cards = "about-module--valuesSection__cards--3aacf";
export var valuesSection__description = "about-module--valuesSection__description--c760e";
export var valuesSection__title = "about-module--valuesSection__title--96517";